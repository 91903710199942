import React from 'react';

function FormImpiantiSpa() {
    return (
        <body>
            <div class="bannerSection">
                <img src="plastic-wrappingbanner.JPG" alt="banner"/>
            </div>
            <div class="mainSection">
                
                <h3>You are the reason we exist.</h3>
                <p id="infoTxt">
                As one of the leading manufacturers and suppliers of block, kerb and paving production machinery and components to the building materials industry we can offer advice and solutions to most requirements.
                </p>
                <p id="infoTxt">
                We plan, manufacture, install, and commission from within all our own resources. We would welcome an opportunity to work with you on any project you might be considering.
                </p>
                <p id="infoTxt">
                Form Impianti Systems: satisfy the special requirements of the "British Standard", which is the relevant standard in many countries e.g. the Middle East, and Africa.
                </p>
                
                <br/>
                
                <h3>Please contact us.</h3>
                <p id="infoTxt">
                Our success has been achieved with the close co-operation with our suppliers at home and abroad.</p>
                <p id="infoTxt">
                We can guarantee fast delivery times for new moulds, wear and spare parts.
                </p>
                <a class="linkBtn" target="_blank" href="mailto:info@casarequipment.com" rel='noreferrer'>info@casarequipment.com</a>
            </div>
        </body>
    );
}

export default FormImpiantiSpa;