import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
        <div class="footerContainer">
            <div class="side">
                <p><b>Links</b></p>
                <a href="terms.php"><p id="TermsfooterText">Terms and Conditions</p></a>
                <a class="footerText" target="_blank" href="mailto:info@casarequipment.com" rel="noreferrer">info@casarequipment.com</a>
                <p id="footerText">Copyright CASAR Equipment 2018</p>
            </div>
            <div class="side">
                <p><b>Address</b></p>
                <p>10 Aghinlig Road</p>
                <p>Dungannon</p>
                <p>Co. Tyrone</p>
                <p>BT71 6SP</p>
            </div>
            <div class="side">
                
            </div>
        </div>
    </footer>
  );
}

export default Footer;