import React from 'react';

function Videos() {
    return (
        <body>
        <div class="headerInsets"></div>
        <div class="mainSection2">
            <h3>Video Library</h3>
            <div class='videoContainer' data-href='videos.php?video=FPR1006StoneRID3'>
                <img src='FPR1006FeedRoller3.png' alt=""/>
                    <div class='textContainer'>
                        <p>FPR1006RollerScalp1</p>
                    </div>
                </div>
            <div class='videoContainer' data-href='videos.php?video=FPR1006FeedRoller'>
                <img src='FPR1006StoneRID3.png' alt=""/>
                    <div class='textContainer'>
                        <p>FPR1006Jaw</p>
                    </div>
                </div>
            <div class='videoContainer' data-href='videos.php?video=FPR1006FeedRoller035mm'>
                <img src='FPR1006FeedRoller0-35mm.png' alt=""/>
                    <div class='textContainer'>
                        <p>FPR1006RollerScalp2</p>
                    </div>
                </div>
            </div>
        </body>
    );
}

export default Videos;