import React from 'react';

function Spares() {
    return (
        <body>
            <div class="bannerSection">
                <img src="sparesBanner.jpg" alt="banner"/>
            </div>
            <div class="mainSection">
                
                <h3>CASAR is the genuine alternative to your original equipment manufacturer.</h3>
                <h3>Drive drums, Motors, Rollers, Original Hardox, and Wear parts, for most brands.</h3>
                <p id="infoTxt">High performance offering Cost-effective savings:</p>
                <p id="infoTxt">CASAR Wear and Spare parts are designed and manufactured in consideration of the original equipment manufacturer's specifications, producing high performance and offering cost-effective savings.</p>
                
                <p id="infoTxt">CASAR are reputed for incorporating original equipment manufacturer's factory tolerances and material specifications.</p>
                
                <h3>Manganese steel wear parts</h3>
                <p id="infoTxt">are a relatively low-cost option, offering good wear life and are ideally suited for high impact applications where tramp metal or uncrushable objects are included in the feed material. Available in standard and premium grade manganese steel.</p>
                
                <h3>Martensitic steel wear parts</h3>
                <p id="infoTxt">are considered ideal in applications where the feed material varies. Offering impact resistance similar to manganese steel with improved wear properties, they also demonstrate good resistance to abrasive gouging.</p>
                
                <h3>Chrome iron wear parts</h3>
                <p id="infoTxt">are ideally suited to applications with high abrasion offering cost effective wear life. They are not suited to applications where tramp metal or uncrushable objects are included in the feed material. Available in medium and high chrome grades.</p>
                <br/>
                
                <h3>Less worry, more profit</h3>
                <p id="infoTxt">CASAR wear parts are the best and surest way to protect your crusher against hazards such as overload, unplanned shutdowns and premature wearing out of main components. You spend less time worrying, and more time achieving your goals. Less downtime,
                 improved performance and consistent end-product quality reduces your cost per ton in the long run.</p>
                <br/>
                
                <h3>Suppliers:</h3>
                
                <h4>Our requirements</h4>
                <p id="infoTxt">Our suppliers are important and essential components in our efforts to provide our customers with high-quality products. To satisfy our customers' needs we make high demands on ourselves, and expect  the same standards from our partners throughout the complete value creation chain;</p>
                
                <ul>
                    <li>Highest quality standards</li>
                    <li>Flexibility and reliability</li>
                    <li>Adherence to delivery dates</li>
                    <li>Competitive conditions</li>
                    <li>Innovativeness</li>
                    <li>Environmental consciousness</li>
                    <li>Suppliers who promote Workplace Wellbeing for their workers.</li>
                </ul>
                
                <p id="infoTxt">Please email us with your proposal, and we will consider your offer and respond within 10 working days.  Please include all your contact details.</p>
                
                <a class="linkBtn" target="_blank" href="mailto:info@casarequipment.com" rel='noreferrer'>info@casarequipment.com</a>
            </div>
        </body>
        );
    }
    
    export default Spares;