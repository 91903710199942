import React from 'react';

function Home() {
    return (
        <body>
        <div class="bannerSection">
            <img src='banner.png' alt="banner"/>
        </div>
        <div class="mainSection">
            <div class='contentContainer'>
                <h3>CASAR gives you more smiles, and less frowns</h3>
                <h3>The longevity of our systems and equipment is just one among many qualities our customers appreciate.</h3>
                
                <p id="infoTxt">CASAR Equipment gives you Longevity, Reliability, Personal service, True value for your hard earned money.</p>
                
                <p id="infoTxt">CASAR is a specialist provider of materials processing equipment for the quarrying and waste recycling industries in the UK and Ireland.</p>
                <p id="infoTxt">CASAR provide total solutions from single equipment specification and supply, to the bespoke design and installation of complete integrated processing systems. With an extensive but focused portfolio of world leading OEM brands in the materials processing sector, CASAR is able to combine the very best in proven equipment with the latest innovative technologies to give you a winning and competitive edge to your business.</p>
                
                <h3>CASAR gives you the 5 Star treatment every time.</h3>
                <p id="infoTxt">We treat others the way we would like to be treated, by offering a friendly, helpful and professional service, designed to support you in your drive for improved production and performance.</p>
                <p id="infoTxt">CASAR believe that the initial sale is only the beginning of our relationship, CASAR place a huge emphasis on After sales service, Parts offering and making sure you get what you want and need to achieve your goals.</p>
                
                <p id="infoTxt">Go on! put us to the test.</p>
                <a class="linkBtn" target="_blank" href="mailto:info@casarequipment.com">info@casarequipment.com</a>
                
                <h3>THE CASAR DIFFERENCE</h3>
                
                <p id="infoTxt">We're customer focused; We think innovatively, and are extremely proud to be a 'can do' organisation. Whether we supply the complete turnkey system or a spares and service support, we'll use our brains as well as our brawn to get the job done. In practice that means problems are solved before they even become an issue. CASAR call it proactive engagement.</p>
                <p id="infoTxt"> For you it means working with a organisation you can depend upon, to understand your objectives and drive your project forward.</p>
                <p id="infoTxt">This means less stress and more profit for you. We understand your commercial goals and are determined to work in partnership with you to achieve them.</p>
                
                <h3>Health and Safety Policy</h3>
                
                <p id="infoTxt">CASAR state clearly to all staff and subcontractors, that if there is any risk that cannot be managed and controlled, then CASAR policy is clear; the job must stop until all safety issues are understood and resolved.  The safety of our team and that of our customers personnel and property, takes precedence over any profit or goal; every time.</p>
                
                <p id="infoTxt">Therefore we insist that our personnel have the following;
                practical training,
                the latest proven safety equipment resources,
                the ability to identify hazards,
                a clear understanding on how to reduce workplace accidents and create safer working conditions.</p>
                
                
                <p id="infoTxt">CASAR objective;
                to provide a safe and healthy workplace for all workers, visitors, contractors and other interested parties to prevent death, work-related injury or ill-health and we continually aim to improve all aspects of our health & safety performance.</p>
                
                <p id="infoTxt">CASAR health & safety polices aim to;</p>
                <p id="infoTxt">Integrate with other management systems.</p>
                <p id="infoTxt">Provide an integrated approach to organisational management.</p>
                <p id="infoTxt">Ensure the organisation establishes clear policies which are compatible with the overall strategic objectives and direction of the organisation.</p>
                <p id="infoTxt">Promote continual improvement across the organisation.</p>
                <p id="infoTxt">Enable the organisation to address and manage risk in the workplace.</p>
                <p id="infoTxt">Set out a preventative and proactive approach which involves identifying workplace hazards and assessing and controlling risk.</p>
                <p id="infoTxt">And if it ever becomes necessary; how to focus and respond to work-related incidents, report them to the responsible department; <b>near misses included</b>, as we know a reported near miss today, can be a life saved tomorrow.</p>
                
              </div>
        </div>
        </body>
    );
}

export default Home;