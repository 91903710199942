import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import CBS from "./pages/CBS";
import Crushers from "./pages/Crushers";
import NoPage from "./pages/NoPage";
import "./index.css";
import Screeners from "./pages/Screeners";
import Videos from "./pages/Videos";
import FormImpiantiSpa from "./pages/FormImpiantiSpa";
import Spares from "./pages/Spares";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="cbs" element={<CBS />} />
          <Route path="crushers" element={<Crushers />} />
          <Route path="screeners" element={<Screeners />} />
          <Route path="videos" element={<Videos />} />
          <Route path="formimpiantispa" element={<FormImpiantiSpa />} />
          <Route path="spares" element={<Spares />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
