import React from 'react';

function Crushers() {
    return (
        <body>
        <div class="bannerSection">
            <img src="jawmax1006.JPG" alt="jawmax"/>
        </div>
        <div class="mainSection">
            <div class='contentContainer'>
                <h3>High Capacity - Outstanding Reliability</h3>
                
                <p id="infoTxt">Mobile machines made by SBM and MFL are used in wet and dry processing of natural stone, sand and gravel, or recycling materials. Mobile versions include plants mounted on tracks or wheels, semi-mobile or containerised versions.
                Meeting the customers' various and most complex demands combined with excellent and globally proven product quality ensure maximum productivity and profitability of the plants on a long-term basis for our clients.
                </p>
                <p id="infoTxt">
                Thanks to the modular design, plant expansions or upgrades such as higher volume hoppers, additional silos or winter equipment are feasible any time.
                </p>
                
                <h3>Designed to perform. Built to last.</h3>
                
                <h3>SBM Mineral Processing</h3>
                
                <p id="infoTxt">
                    SBM Mineral Processing GmbH, an Austrian company with a long-standing tradition, ranks among the leading full-package suppliers of mineral processing plants and conveying plants for the natural stones and recycling industries and concrete mixing plants for ready-mixed and prefab concrete. In the business field mineral processing, SBM carries the brands SBM and MFL. 
                    SBM stands for more than 60 years of experience and is a globally well-established comprehensive supplier for highest demands.
                </p>
                <p id="infoTxt">
                The product portfolio covers individual machines, mobile and stationary mineral processing plants and concrete mixing plants as well as service and support. All products are manufactured according to most demanding quality criteria. SBM has 130 employees and belongs to the MFL Corporate Group.
                </p>
                
                <a class="linkBtn" target="_blank" href="mailto:info@casarequipment.com" rel="noreferrer">info@casarequipment.com</a>
            </div>
        </div>
    </body>
    );
}

export default Crushers;