import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

function Navbar() {
  return (
    <>
        <head>
        <link rel="stylesheet" href="stylesheet.css" type="text/css"/>
        <script src="scripts/jquery.js"></script>
        <script src="scripts/javascript.js"></script>
        <link rel="SHORTCUT ICON" href="../images/favicon.ico"/>
        <meta name="msapplication-TileColor" content="#bb9603"/>
        <title>CASAR Equipment</title>
        </head>
        <header>
            <div id="background"></div>
            <ul>
                <li class="Logolist">
                    <Link to="/">
                        {/* <img class="logo" id="logoclear" src="whiteLogo.png" alt="logo"/> */}
                        <img class="logo" id="logosolid" src="casar.png" alt="logo"/>
                    </Link>
                </li>
            </ul>
            <ul class="navList">
                <li><Link to="/"><img class="listLogo" src="whiteLogo.png" alt="logo"/></Link></li>
                <li><Link to="/">HOME</Link></li>
                <li><Link to="CBS">CONCRETE BATCHING</Link></li>
                <li><Link to="screeners">SEPARATION</Link></li>
                <li><Link to="crushers">CRUSHERS</Link></li>
                <li><Link to="formimpiantispa">BLOCK MAKING</Link></li>
                <li><Link to="spares">SPARE PARTS</Link></li>
                <li><Link to="videos">VIDEOS</Link></li>
            </ul>
        </header>
    </>
  );
}

export default Navbar;